.um-okkur_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url('../../resources/umokkurbackground.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: fixed;
  width: 100%;
  height: 100%;
}

.um-okkur__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.6);
    padding: 5px;
    width: 60%;
    height: 100%;
    margin: 200px;
}

.um-okkur__left__header h1 {
  font-size: 40px;
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: "Cinzel", "serif";
  font-weight: 100;
  padding: 0;
}

.um-okkur__left__text p {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-family: "Cinzel", "serif";
    margin: 20px;
    padding: 0;
}

@media only screen and (max-width: 1024px){
  .um-okkur__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.6);
    width: 80%;
    height: 100%;
    margin-top: 150px;
    margin-left: 10%;
    margin-right: 0px;
    margin-bottom: 150px;
  }

  .um-okkur__left__header h1 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: "Cinzel", "serif";
    font-weight: 100;
    padding: 0;
  }

  .um-okkur__left__text p {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-family: "Cinzel", "serif";
    margin: 20px;
    padding: 0;
  }
  
}


@media only screen and (max-width: 600px) {
  .um-okkur__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.6);
    width: 90%;
    height: 100%;
    margin-top: 100px;
    margin-left: 5%;
    margin-right: 0px;
    margin-bottom: 100px;
  }
  .um-okkur__left__text p {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-family: "Cinzel", "serif";
    margin: 20px;
    padding: 0;
  }
}