.container_album {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(169, 132, 104);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.vara-header-album {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 0px;
    width: 80%;
    height: 120px;
    border: 5px solid rgb(255, 255, 255);
}

.vara-header-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #40b6e9;
    border: none;
    border-radius: 10px;
    width: 200px;
    padding: 10px;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}

.vara-header-album h1 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: 'Cinzel', 'serif';
    font-weight: 100;
    margin-bottom: 0;
    padding: 0;
}