.vara {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 60%;
    height: 200px;
    border: 5px solid rgb(255, 255, 255);
    margin-bottom: 5%;
    background-color: rgb(0, 0, 0);
}

.vara-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    
}

.vara img {
    width: 100%;
    height: 80%;
    object-fit: contain;
}

.vara-info {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    padding: 10px;
}

.vara-info h3 {
    font-size: 30px;
    font-weight: 100;
    font-family: 'Cinzel', 'serif';
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 0;
    color: white;
}

.vara-info p {
    font-size: 20px;
    font-family: 'Cinzel', 'serif';
    color: white;
    margin: 0;
    padding: 0;
}



@media only screen and (max-width: 600px) {
    .vara {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        width: 90%;
        height: 200px;
        border: 5px solid rgb(255, 255, 255);
        margin-bottom: 5%;
        background-color: rgb(0, 0, 0);
    }
    
    .vara-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        
    }
    
    .vara img {
        width: 100%;
        height: 80%;
        object-fit: contain;
    }
    
    .vara-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 50%;
        padding: 10px;
    }
    
    .vara-info h3 {
        font-size: 20px;
        font-weight: 100;
        font-family: 'Cinzel', 'serif';
        margin-top: 20px;
        margin-bottom: 15px;
        padding: 0;
        color: white;
    }
    
    .vara-info p {
        font-size: 15px;
        font-family: 'Cinzel', 'serif';
        color: white;
        margin: 0;
        padding: 0;
    }
    
}

