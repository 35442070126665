@media (max-width: 640px) {
    .bg-cover {
        background-size: cover;
    }
    .bg-fixed {
        background-attachment: scroll;
    }
    .bg-center {
        background-position: center;
    }

    .min-h-screen {
        min-height: 50vh;
    }

    .h-96 {
        height: 50vh;
    }
}