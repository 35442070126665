/* styles.css */
.custom-overlay {
    background-color: rgba(0, 0, 0, 0.75);
}

.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 8px;
    border-color: white;
    border-width: 2px;
    outline: none;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.alert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}

.alert-title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Cinzel', serif;
    color: white;
}

.alert-message {
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
    color: white;
    font-family: 'Cinzel', serif;
}

.alert-button {
    padding: 10px 20px;
    background-color: rgba(194,153,121,1);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.alert-button:hover {
    background-color: rgba(194,153,121,0.8);
}
