.súpuplan {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: #000000;
    width: 100%;
    margin: 0;
}

.divider {
    width: 50%;
    background-color: white;
    position: relative;
    height: 1px;
    margin-bottom: 1px;
}

.súpuplan h2 {
    font-size: 40px;
    font-weight: 100;
    font-family: 'Cinzel', 'serif';
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 0;
}

.súpuplan p {
    font-size: 20px;
    font-weight: 100;
    font-family: 'Cinzel', 'serif';
    margin-bottom: 1px;
    padding: 0;
}

.súpuplan-text {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.weekday {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    margin: 20px;
}

.weekday p {
    font-size: 20px;
    font-weight: 100;
    font-family: 'Cinzel', 'serif';
    margin-top: 20px;
    padding: 0;
}


@media only screen and (max-width: 1024) {
    .súpuplan {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        background-color: #000000;
        width: 100%;
        margin: 0;
    }

    .divider {
        width: 50%;
        background-color: white;
        position: relative;
        height: 1px;
        margin-bottom: 1px;
    }

    .súpuplan h2 {
        font-size: 40px;
        font-weight: 100;
        font-family: 'Cinzel', 'serif';
        margin-top: 20px;
        margin-bottom: 15px;
        padding: 0;
    }

    .súpuplan p {
        font-size: 20px;
        font-weight: 100;
        font-family: 'Cinzel', 'serif';
        margin-bottom: 1px;
        padding: 0;
    }

    .súpuplan-text {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .weekday {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        margin: 20px;
    }

    .weekday p {
        font-size: 20px;
        font-weight: 100;
        font-family: 'Cinzel', 'serif';
        margin-top: 20px;
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .súpuplan {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        background-color: #000000;
        width: 100%;
        margin: 0;
    }

    .divider {
        width: 50%;
        background-color: white;
        position: relative;
        height: 1px;
        margin-bottom: 1px;
    }

    .súpuplan h2 {
        font-size: 40px;
        font-weight: 100;
        font-family: 'Cinzel', 'serif';
        margin-top: 20px;
        margin-bottom: 15px;
        padding: 0;
    }

    .súpuplan p {
        font-size: 20px;
        font-weight: 100;
        font-family: 'Cinzel', 'serif';
        margin-bottom: 1px;
        padding: 0;
    }

    .súpuplan-text {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .weekday {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;
        height: 100%;
        margin: 20px;
    }

    .weekday p {
        font-size: 20px;
        font-weight: 100;
        font-family: 'Cinzel', 'serif';
        margin-top: 20px;
        padding: 0;
    }
}