.navigation-link {
    display: flex;
    margin: 5px;
    align-items: center;
  }
  
  .navigation-link a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
  }

.navigation-link a:hover {
    color:  #f1b143;
}

.navigation-link__button {
    background-color: #40b6e9;
    border: none;
    border-radius: 10px;
    padding: 10px;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
  }


  @media only screen and (max-width: 1024px) {
    .navigation-link {
        display: flex;
        align-items: center;
      }
      
      .navigation-link a {
        text-decoration: none;
        color: rgb(255, 255, 255);
        font-size: 1.2rem;
        font-family: 'Cinzel', serif;
      }
    
    .navigation-link a:hover {
        color:  #f1b143;
    }
    
    .navigation-link__button {
        border: none;
        border-radius: 10px;
        padding: 10px;
        color: rgb(0, 0, 0);
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;
      }
  }


  @media only screen and (max-width: 600px){
    .navigation-link {
        display: flex;
        align-items: center;
        margin: -2px;
      }
      
      .navigation-link a {
        text-decoration: none;
        color: rgb(255, 255, 255);
        font-size: 1rem;
        font-family: 'Cinzel', serif;
      }
    
    .navigation-link a:hover {
        color:  #f1b143;
    }
    
  }

@media only screen and (max-width: 440) {
    .navigation-link {
        display: flex;
        align-items: center;
        margin: -2px;
      }
      
      .navigation-link a {
        text-decoration: none;
        color: rgb(255, 255, 255);
        font-size: 0.8rem;
        font-family: 'Cinzel', serif;
      }
    
    .navigation-link a:hover {
        color:  #f1b143;
    }
  
}
  
  


