.hafa-samband_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: url('../../resources/testbackground.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
}

.hafa-samband__form__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 80%;
    height: 30%;
}

.hafa-samband__form__header h1 {
    font-size: 2rem;
    font-family: 'Cinzel', serif;
    font-weight: 50;
    color: #ffffff;
}

.hafa-samband__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 70%;
}

.hafa-samband__contact p {
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
    color: #ffffff;
}

.hafa-samband__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 80%;
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}

.hafa-samband__form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 80%;
}

.hafa-samband__form__name {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 10px;
    width: 50%;
}

.hafa-samband__form__name label {
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
    color: #ffffff;
}

.hafa-samband__form__name input {
    width: 100%;
    height: 40px;
    border: none;
    margin-bottom: 10px;
}

.hafa-samband__form__email {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-bottom: 10px;
    width: 50%;
}

.hafa-samband__form__email label {
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
    color: #ffffff;
}

.hafa-samband__form__email input {
    width: 100%;
    height: 40px;
    border: none;
    margin-bottom: 10px;
}

.hafa-samband__form__phone {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-bottom: 10px;
    width: 50%;
}

.hafa-samband__form__phone label {
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
    color: #ffffff;
}

.hafa-samband__form__phone input {
    width: 100%;
    height: 40px;
    border: none;
    margin-bottom: 10px;
}

.hafa-samband__form__subject {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-bottom: 20px;
    width: 50%;
}

.hafa-samband__form__subject label {
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
    color: #ffffff;
}

.hafa-samband__form__subject textarea {
    width: 100%;
    height: 100px;
    border: none;
    margin-bottom: 10px;
}

.send_button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

.send_button button {
    width: 200px;
    height: 50px;
    background-color: rgb(194, 153, 121, 100);
    color: white;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    border: white 1px solid;
}

.white_line {
    width: 90%;
    height: 0.6px;
    background-color: white;
    margin-left: 10%;
    }


    @media only screen and (max-width: 1024px) {
        .hafa-samband_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-image: url('../../resources/testbackground.jpeg');
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
        }

        .hafa-samband__form__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            width: 60%;
            height: 30%;
        }

        .hafa-samband__form__header h1 {
            font-size: 1.5rem;
            font-family: 'Cinzel', serif;
            font-weight: 50;
            color: #ffffff;
        }

        .hafa-samband__contact {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: 70%;
        }


        .hafa-samband__form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            height: 80%;
            margin-top: 5%;
            background-color: #000000;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
            margin-bottom: 5%;
        }

        .hafa-samband__form form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            height: 80%;
        }

        .hafa-samband__form__name {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-bottom: 10px;
            margin-top: 2%;
            width: 50%;
        }

        .hafa-samband__form__name label {
            font-size: 1.2rem;
            font-family: 'Cinzel', serif;
            color: #ffffff;
            margin-right: 0%;
        }

        .hafa-samband__form__name
        input {
            width: 100%;
            height: 40px;
            border: none;
            margin-bottom: 10px;
        }

        .hafa-samband__form__email {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            margin-bottom: 10px;
            width: 50%;
        }

        .hafa-samband__form__email label {
            font-size: 1.2rem;
            font-family: 'Cinzel', serif;
            color: #ffffff;
        }

        .hafa-samband__form__email input {
            width: 100%;
            height: 40px;
            border: none;
            margin-bottom: 10px;
        }

        .hafa-samband__form__phone {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            margin-bottom: 10px;
            width: 50%;
        }

        .hafa-samband__form__phone label {
            font-size: 1.2rem;
            font-family: 'Cinzel', serif;
            color: #ffffff;
            
        }

        .hafa-samband__form__phone input {
            width: 100%;
            height: 40px;
            border: none;
            margin-bottom: 10px;
        }

        .hafa-samband__form__subject {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            margin-bottom: 20px;
            width: 50%;
        }

        .hafa-samband__form__subject label {
            font-size: 1.2rem;
            font-family: 'Cinzel', serif;
            color: #ffffff;
        }

        .hafa-samband__form__subject textarea {
            width: 100%;
            height: 100px;
            border: none;
            margin-bottom: 10px;
        }

        .send_button {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 50px;
        }

        .send_button button {
            width: 220px;
            height: 50px;
            background-color: rgb(194, 153, 121, 100);
            color: white;
            font-size: 20px;
            font-family: 'Roboto', sans-serif;
            border: white 1px solid;
        }

        .white_line {
            width: 90%;
            height: 0.6px;
            background-color: white;
            margin-left: 10%;
        }





    }