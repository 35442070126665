.container_smurt {
    display: flex;
    flex-direction: column;
    background-image: url('../../resources/testbackground.jpeg');
    background-size: contain;
    align-items: center;
}

.vara-header_smurt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    margin-top: 30px;
    margin-left: 40px;
    width: 60%;
    height: 150px;
    border: 5px solid rgb(255, 255, 255);
}
.vara-header_smurt h1 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: 'Cinzel', 'serif';
    font-weight: 100;
    padding: 0;
}

.vara-header_smurt p {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-family: 'Cinzel', 'serif';
    margin: 0;
    padding: 0;
}

.vara-list_smurt {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .vara-header_smurt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;
        margin-top: 30px;
        margin-left: 0px;
        width: 90%;
        height: 120px;
        border: 5px solid rgb(255, 255, 255);
    }
    .vara-header_smurt p {
        font-size: 20px;
        color: rgb(255, 255, 255);
        text-align: center;
        font-family: 'Cinzel', 'serif';
        font-weight: 100;
        margin: 0;
        padding: 0;
    }
    .vara-header_smurt h1 {
        font-size: 40px;
        color: rgb(255, 255, 255);
        text-align: center;
        font-family: 'Cinzel', 'serif';
        font-weight: 100;
        margin: 0;
        padding: 0;
    }
    .vara-list_smurt {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    
}

@media only screen and (max-width: 600px) {
    .vara-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;
        margin-top: 30px;
        margin-left: 0px;
        width: 90%;
        height: 120px;
        border: 5px solid rgb(255, 255, 255);
    }
    .vara-header p {
        font-size: 20px;
        color: rgb(255, 255, 255);
        text-align: center;
        font-family: 'Cinzel', 'serif';
        font-weight: 100;
        margin: 0;
        padding: 0;
    }
    .vara-header h1 {
        font-size: 40px;
        color: rgb(255, 255, 255);
        text-align: center;
        font-family: 'Cinzel', 'serif';
        font-weight: 100;
        margin: 0;
        padding: 0;
    }
    .vara-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    
}

